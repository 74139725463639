.mod {
  justify-content: flex-start;
  align-self: center;
  width: 100%;
  height: 4512px;
  background-color: rgba(1, 1, 1, 1);
  background-size: 1440px 1036px, 579px 649px, 537px 649px;
  font-family: Source Sans Pro;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding-top: 20px;
  background-position: 0px 700px, 861px 67px, 0px 67px;
  background-repeat: no-repeat, no-repeat, no-repeat;
}
.header {
  justify-content: center;
  align-self: center;
  width: 100%;
}
.block {
  justify-content: space-between;
  align-self: flex-start;
  width: 904px;
  height: 24px;
}
.logo {
  align-self: center;
  width: 129px;
  height: 23px;
  margin-bottom: 1px;
  background-image: url(../images/img_0.png);
  background-size: 100% 100%;
  background-position: 0px 0px, 0px 0px;
  background-repeat: no-repeat;
}
.container {
  justify-content: space-between;
  align-self: center;
  width: 417px;
  height: 24px;
  gap: 24px;
}
.our-passion {
  align-self: center;
  border: 1px solid transparent;
  font-size: 17px;
  color: rgba(168, 170, 178, 1);
  text-align: center;
  white-space: nowrap;
  gap: 24px;
  outline: none;
}
.avatarapp {
  align-self: center;
  border: 1px solid transparent;
  font-size: 17px;
  color: rgba(168, 170, 178, 1);
  text-align: center;
  white-space: nowrap;
  gap: 24px;
  outline: none;
}
.about {
  align-self: center;
  border: 1px solid transparent;
  font-size: 17px;
  color: rgba(168, 170, 178, 1);
  text-align: center;
  white-space: nowrap;
  gap: 24px;
  outline: none;
}
.contact {
  align-self: center;
  border: 1px solid transparent;
  font-size: 17px;
  color: rgba(168, 170, 178, 1);
  text-align: center;
  white-space: nowrap;
  gap: 24px;
  outline: none;
}
.headmain {
  justify-content: flex-start;
  align-self: center;
  width: 1440px;
  height: 1026px;
  background-image: url(../images/img_1.png),
    url(../images/img_2.png);
  background-size: 579px 649px, 537px 649px;
  background-position: 861px 0px, 0px 0px;
  background-repeat: no-repeat, no-repeat;
}
.info {
  align-self: center;
  overflow: hidden;
  width: 50%;
  height: 25%;
  margin: 191px 0 0 5px;
  font-size: 70px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  white-space: break-spaces;
  text-overflow: ellipsis;
  webkit-line-clamp: 2;
  webkit-box-orient: vertical;
}
.summary {
  align-self: center;
  overflow: hidden;
  width: 589px;
  height: 10%;
  margin-top: 27px;
  font-size: 21px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  white-space: break-spaces;
  text-overflow: ellipsis;
  webkit-line-clamp: 2;
  webkit-box-orient: vertical;
}
.body {
  justify-content: center;
  align-self: center;
  width: 20%;
  height: 52px;
  margin-top: 47px;
}
.scroll-down {
  align-self: flex-start;
  overflow: hidden;
  max-width: 1026px;
  padding: 16px 32px;
  border: 1px solid #000;
  margin-left: 1px;
  background: var(
    --S-button,
    linear-gradient(99deg, #8c01fa -2.99%, #000 102.28%)
  );
  font-family: Roboto;
  font-size: 15px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 36px;
  border-radius: 36px;
}
.our-passion-tag {
  justify-content: flex-start;
  align-self: center;
  width: 100%;
  background-image: url(../images/img_3.png);
  background-size: 100% 100%;
  padding-top: 323px;
  background-position: 0px 0px;
  background-repeat: no-repeat;
  background-origin: padding-box;
}
.class-6 {
}
.block-1 {
  justify-content: flex-start;
  align-self: center;
  width: 1028px;
}
.our-passion-1 {
  align-self: flex-start;
  overflow: hidden;
  width: 217px;
  height: 50%;
  margin: 77px 0 0 61px;
  font-size: 48px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  white-space: break-spaces;
  text-overflow: ellipsis;
  webkit-line-clamp: 2;
  webkit-box-orient: vertical;
}
.container-1 {
  justify-content: flex-start;
  align-self: flex-start;
  height: 336px;
  margin-left: 113px;
}
.wrapper {
  justify-content: flex-start;
  align-self: center;
  width: 637px;
}
.more-fun {
  align-self: flex-start;
  overflow: hidden;
  max-width: 637px;
  background: linear-gradient(262deg, #19fb9b -8.01%, #8c01fa 100%);
  font-size: 100px;
  color: transparent;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-clip: text;
  webkit-background-clip: text;
  webkit-text-fill-color: transparent;
}
.title {
  align-self: flex-start;
  overflow: hidden;
  max-width: 637px;
  font-family: Roboto;
  font-size: 16px;
  color: rgba(158, 159, 160, 1);
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.wrapper-1 {
  justify-content: flex-start;
  align-self: center;
  width: 637px;
  margin-top: 32px;
}
.better-use {
  align-self: center;
  background: var(
    --s-gradient-2,
    linear-gradient(260deg, #0047ff -13.37%, #00bcd4 109.53%)
  );
  font-size: 100px;
  color: transparent;
  text-align: left;
  white-space: nowrap;
  background-clip: text;
  webkit-background-clip: text;
  webkit-text-fill-color: transparent;
}
.summary-1 {
  align-self: center;
  font-family: Roboto;
  font-size: 16px;
  color: rgba(158, 159, 160, 1);
  text-align: left;
  white-space: nowrap;
}
.block-2 {
  justify-content: flex-start;
  align-self: center;
  width: 1028px;
  margin-top: 32px;
}
.more {
  align-items: flex-start;
  align-self: stretch;
  margin-left: 391px;
  background: linear-gradient(265deg, #00ffbd -5.84%, #025b8c 111.58%);
  font-size: 100px;
  color: transparent;
  text-align: left;
  white-space: nowrap;
  background-clip: text;
  webkit-background-clip: text;
  webkit-text-fill-color: transparent;
}
.summary-2 {
  align-self: flex-end;
  overflow: hidden;
  width: 596px;
  height: 55px;
  margin-right: 41px;
  font-family: Roboto;
  font-size: 16px;
  color: rgba(158, 159, 160, 1);
  text-align: left;
  white-space: break-spaces;
  text-overflow: ellipsis;
  webkit-line-clamp: 2;
  webkit-box-orient: vertical;
}
.main {
  position: relative;
  align-self: center;
  width: 1440px;
  height: 1796px;
  margin-top: 148px;
}
.app-tag {
  position: absolute;
  left: 50%;
  top: 0px;
  flex-direction: column;
  width: 100%;
  height: 60%;
  background-image: url(../images/img_4.png);
  background-size: 100% 100%;
  transform: translateX(-50%);
  background-position: 0px 0px;
  background-repeat: no-repeat;
  background-origin: padding-box;
}
.class-5 {
}
.container-2 {
  position: absolute;
  left: 50%;
  top: 0px;
  justify-content: flex-start;
  width: 100%;
  height: 60%;
  padding: 127px 12px 135px;
  transform: translateX(-50%);
}
.wrapper-2 {
  justify-content: flex-start;
  align-self: center;
  width: 1242px;
}
.group {
  justify-content: flex-start;
  align-self: flex-start;
  width: 602px;
  margin: 131px 0 0 141px;
}
.action-bg {
  align-self: flex-start;
  width: 334px;
  height: 72px;
  background-image: url(../images/img_5.png);
  background-size: 100% 100%;
  background-position: 0px 0px, 0px 0px;
  background-repeat: no-repeat;
}
.photo-production {
  align-self: flex-start;
  overflow: hidden;
  max-width: 602px;
  margin-top: 40px;
  font-family: HarmonyOS Sans;
  font-size: 48px;
  font-weight: 900;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.item-long-wrapper {
  position: relative;
  justify-content: flex-start;
  align-self: flex-start;
  height: 760px;
}
.article {
  position: absolute;
  left: -602px;
  top: 314px;
  overflow: hidden;
  z-index: 1;
  width: 617px;
  height: 25%;
  font-family: Inter;
  font-size: 24px;
  color: rgba(109, 111, 125, 1);
  text-align: left;
  white-space: break-spaces;
  text-overflow: ellipsis;
  webkit-line-clamp: 4;
  webkit-box-orient: vertical;
}
.item-long {
  bottom: 30%;
  align-self: flex-start;
  z-index: 2;
  width: 499px;
  height: 100%;
  background-image: url(../images/img_6.png);
  background-size: 100% 100%;
  background-position: 0px 0px, 0px 0px;
  background-repeat: no-repeat;
}
.class-2 {
  position: absolute;
  top: 50%;
  align-items: center;
  width: 100%;
  height: 200px;
}
.user-talks {
  align-self: flex-start;
  margin: 80px 0 0 10%;
  font-size: 48px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  white-space: nowrap;
}
.wrapper-3 {
  position: absolute;
  right: 0px;
  bottom: 32%;
  justify-content: flex-start;
  width: 20%;
  padding: 32px 24px;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
  margin-right: 10%;
  background: rgba(63, 64, 72, 0.28);
  border-radius: 24px;
  backdrop-filter: blur(12px);
}
.jonathan-shelton-wrapper {
  justify-content: flex-start;
  align-self: center;
  width: 231px;
  border-left: 1px solid rgba(255, 213, 18, 1);
}
.jonathan-shelton {
  align-self: flex-start;
  margin-left: 13px;
  font-size: 28px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  white-space: nowrap;
}
.article-1 {
  align-self: flex-start;
  overflow: hidden;
  height: 120px;
  margin: 10px 10px 10px 10px;
  font-size: 17px;
  color: rgba(196, 196, 196, 1);
  text-align: left;
  white-space: break-spaces;
  text-overflow: ellipsis;
  webkit-line-clamp: 5;
  webkit-box-orient: vertical;
}
.container-3 {
  position: absolute;
  right: 0px;
  bottom: 25%;
  align-items: center;
  width: 20%;
  padding: 32px 24px;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
  margin-right: 40%;
  background: rgba(63, 64, 72, 0.28);
  border-radius: 24px;
  backdrop-filter: blur(12px);
}
.vertical-line {
  align-self: flex-start;
  width: 1px;
  height: 33px;
  margin-bottom: 1px;
  background-color: rgba(31, 207, 241, 1);
}
.callie-garcia {
  align-self: flex-start;
  overflow: hidden;
  max-width: 802px;
  margin-left: 13px;
  font-size: 28px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.summary-3 {
  align-self: flex-start;
  overflow: hidden;
  height: 168px;
  margin-top: 10px;
  font-size: 17px;
  color: rgba(196, 196, 196, 1);
  text-align: left;
  white-space: break-spaces;
  text-overflow: ellipsis;
  webkit-line-clamp: 7;
  webkit-box-orient: vertical;
}
.container-4 {
  position: absolute;
  right: 0px;
  bottom: 10%;
  justify-content: flex-start;
  width: 20%;
  padding: 32px 24px;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
  margin-right: 10%;
  background: rgba(63, 64, 72, 0.28);
  border-radius: 24px;
  backdrop-filter: blur(12px);
}
.cynthia-norton-wrapper {
  justify-content: flex-start;
  align-self: center;
  border-left: 1px solid rgba(25, 251, 155, 1);
}
.cynthia-norton {
  align-self: flex-start;
  overflow: hidden;
  max-width: 486px;
  margin-left: 13px;
  font-size: 28px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.article-2 {
  align-self: flex-start;
  overflow: hidden;
  width: 232px;
  height: 165px;
  margin-top: 13px;
  font-size: 17px;
  color: rgba(196, 196, 196, 1);
  text-align: left;
  white-space: break-spaces;
  text-overflow: ellipsis;
  webkit-line-clamp: 7;
  webkit-box-orient: vertical;
}
.block-4 {
  position: absolute;
  left: 10%;
  bottom: 22%;
  justify-content: flex-start;
  width: 20%;
  padding: 32px 24px;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
  background: rgba(63, 64, 72, 0.28);
  border-radius: 24px;
  backdrop-filter: blur(12px);
}
.sunny-wrapper {
  justify-content: flex-start;
  align-self: center;
  width: 100%;
  border-left: 1px solid rgba(31, 207, 241, 1);
}
.sunny {
  align-self: flex-start;
  overflow: hidden;
  max-width: 290px;
  margin-left: 13px;
  font-size: 28px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.article-3 {
  align-self: flex-start;
  overflow: hidden;
  width: 232px;
  height: 120px;
  margin-top: 10px;
  font-size: 17px;
  color: rgba(196, 196, 196, 1);
  text-align: left;
  white-space: break-spaces;
  text-overflow: ellipsis;
  webkit-line-clamp: 5;
  webkit-box-orient: vertical;
}
.block-5 {
  position: absolute;
  left: 10%;
  bottom: 0%;
  justify-content: flex-start;
  width: 20%;
  padding: 32px 24px;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
  background: rgba(63, 64, 72, 0.28);
  border-radius: 24px;
  backdrop-filter: blur(12px);
}
.container-5 {
  justify-content: flex-start;
  align-self: center;
  width: 100%;
  border-left: 1px solid rgba(31, 207, 241, 1);
}
.vertical-line-1 {
  align-self: flex-end;
  width: 1px;
  height: 33px;
  margin-bottom: 1px;
  background-color: rgba(153, 69, 255, 1);
}
.sylver {
  align-self: flex-start;
  width: 100%;
  margin-left: 12px;
  font-size: 28px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  white-space: nowrap;
}
.article-4 {
  align-self: flex-start;
  overflow: hidden;
  height: 168px;
  margin-top: 10px;
  font-size: 17px;
  color: rgba(196, 196, 196, 1);
  text-align: left;
  white-space: break-spaces;
  text-overflow: ellipsis;
  webkit-line-clamp: 7;
  webkit-box-orient: vertical;
}
.empty {
  position: absolute;
  right: -656px;
  bottom: -148px;
  width: 0px;
  height: 0px;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-size: 100% 100%;
  background-position: 0px 0px;
  background-repeat: no-repeat;
}
.block-6 {
  position: absolute;
  right: 0px;
  bottom: 3%;
  align-items: center;
  width: 20%;
  padding: 32px 24px;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
  margin-right: 40%;
  background: rgba(63, 64, 72, 0.28);
  border-radius: 24px;
  backdrop-filter: blur(12px);
}
.george-wrapper {
  justify-content: flex-start;
  align-self: center;
  width: 100%;
  border-left: 1px solid rgba(31, 207, 241, 1);
}
.george {
  align-self: flex-start;
  overflow: hidden;
  max-width: 802px;
  margin-left: 13px;
  font-size: 28px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.article-5 {
  align-self: flex-start;
  overflow: hidden;
  height: 168px;
  margin-top: 10px;
  font-size: 17px;
  color: rgba(196, 196, 196, 1);
  text-align: left;
  white-space: break-spaces;
  text-overflow: ellipsis;
  webkit-line-clamp: 7;
  webkit-box-orient: vertical;
}
.about-tag {
  justify-content: flex-start;
  align-self: center;
  width: 1440px;
  height: 1026px;
  padding: 6% 12px 171px;
  margin-top: 93px;
  background-image: url(../images/img_7.png);
  background-size: 100% 100%;
  background-position: 0px 0px;
  background-repeat: no-repeat;
  background-origin: padding-box;
}
.class-4 {
}
.about-1 {
  align-self: center;
  overflow: hidden;
  max-width: 1406px;
  margin-left: 5px;
  font-family: Inter;
  font-size: 50px;
  font-weight: 900;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.summary-4 {
  align-self: center;
  overflow: hidden;
  width: 831px;
  height: 72px;
  margin: 16px 0 0 3px;
  font-family: Inter;
  font-size: 24px;
  color: rgba(172, 174, 187, 1);
  text-align: center;
  white-space: break-spaces;
  text-overflow: ellipsis;
  webkit-line-clamp: 2;
  webkit-box-orient: vertical;
}
.block-7 {
  justify-content: center;
  align-self: center;
  width: 834px;
  padding: 0px 0px;
  border: 1px solid rgba(255, 255, 255, 0.36);
  margin-top: 10%;
  background: rgba(54, 55, 62, 0.38);
  border-radius: 20px;
  backdrop-filter: blur(20px);
}
.class-3 {
}
.container-6 {
  justify-content: flex-start;
  align-self: center;
  width: 493px;
  height: 353px;
  padding: 58px 0 59px;
}
.contact-1 {
  align-self: flex-start;
  overflow: hidden;
  max-width: 493px;
  font-size: 48px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.summary-5 {
  align-self: flex-start;
  overflow: hidden;
  width: 413px;
  height: 48px;
  margin-top: 16px;
  font-size: 17px;
  color: rgba(196, 196, 196, 1);
  text-align: left;
  white-space: break-spaces;
  text-overflow: ellipsis;
  webkit-line-clamp: 2;
  webkit-box-orient: vertical;
}
.summary-6 {
  align-self: flex-start;
  overflow: hidden;
  width: 401px;
  height: 60px;
  margin-top: 56px;
  font-size: 15px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  white-space: break-spaces;
  text-overflow: ellipsis;
  webkit-line-clamp: 3;
  webkit-box-orient: vertical;
}
.item {
  align-self: center;
  width: 319px;
  height: 319px;
  background-image: url(../images/img_8.png);
  background-size: 100% 100%;
  background-position: 0px 0px, 0px 0px;
}
.footer {
  height: 160px;
}
.class-divider {
  height: 1px;
  opacity: 0.2;
}
.class-footer-content {
  align-items: center;
  width: 100%;
  height: 40%;
}
.class-7 {
  padding: 10px;
  font-size: 12px;
  color: rgba(196, 196, 196, 1);
}
.class-9 {
  font-size: 12px;
  color: rgba(196, 196, 196, 1);
}
.class-8 {
  padding: 10px;
  font-size: 12px;
  color: rgba(196, 196, 196, 1);
}
